.profile {
    background: var(--var-color-primary);
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
}

.profile-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.profile-cont__card {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
}

.profile-cont__card-header {
    font-size: 1.1rem;
    font-weight: 700;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(198, 189, 189);
    width: 90%;
}

.profile-cont__card-info {
    font-size: .8rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.profile-cont__card-info-item {
    padding: 0;
    margin: 0;
}

.profile-cont__card-info-item__value {
    font-weight: 700;
    margin-top: .2rem;
    font-size: .9rem;
}

.profile-cont__card-link {
    color: var(--var-color-text);
    font-size: 1rem;
}

.profile-cont__card-info-options {
    margin-bottom: .5rem;
}

.profile-cont__card-form__btn {
    margin: 0;
    width: 11rem;
    font-size: .8rem;
    font-weight: 700;
    color: var(--var-color-primary);
    background: var(--var-color-text);
    padding: .5rem 1rem;
    margin: .5rem 0 0 0;
    transition: all .3s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.profile-cont__card-form__btn-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.profile-cont__card-form__btn:hover {
    background: var(--var-color-text);
    color: var(--var-color-primary);
    opacity: .8;
}

.profile-cont__card-form__btn:focus {
    outline: none;
    border: 1px solid var(--var-color-text);
}

.profile-note {
    font-size: .9rem;
    font-weight: 300;
    margin-top: 1rem;
}

.subscribe__header {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2rem 0 0 0;
    text-align: left;
}

.subscribe__header-note {
    font-size: 1rem;
    font-weight: 500;
    margin: .3rem 0 0 0;
}

.subscribe__card-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem 0 1.4rem 0;
    gap: .8rem;
    height: 100%;
}

.subscribe__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: var(--var-color-text);
    color: var(--var-color-primary);
    border-radius: 3px;
    padding: 1rem;
    height: 100% !important;
    font-size: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.subscribe__card:first-child {
    background: var(--var-color-background);
    color: var(--var-color-text);
}

.subscribe__card-header {
    font-size: 1.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 700;
    padding-bottom: .5rem;
}

.subscribed__card-header__note {
    font-size: 1.3rem;
    margin-top: .5rem;
}

.subscribe__card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 1rem;
}

.subscribe__card-text-cont {
    width: 100%;
}

.subscribe__card-price {
    margin: 0 0 .5rem 0;
    font-size: 1.3rem;
}

.subscribe__card-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 3rem;
    font-weight: 600;
}

.subscribe__btn {
    width: 100%;
    font-size: 1.1rem;
    font-weight: 700;
    text-align: center;
    color: var(--var-color-text);
    background: var(--var-color-primary);
    padding: .5rem 1rem;
    margin: 0;
    transition: all .3s;
    border-radius: 3px;
}

.subscribe__btn:hover {
    background: var(--var-color-primary);
    color: var(--var-color-text);
    opacity: .8;
    cursor: pointer;
}

.subscribe__btn:focus {
    outline: none;
    border: 1px solid var(--var-color-primary);
}


.subscribe__card-body p {
    margin: 0 0 .2rem 0;
    font-size: 1.1rem;
    font-weight: 500;
}

.cont__card-subscription-info {
    margin: 1rem 0 0 0;
    font-weight: 500;
    font-size: 1.1rem;
}

@media screen and (min-width: 768px) {

    .profile-cont__card-header {
        font-size: 1.5rem;
    }

    .profile-cont__card-info-item__value {
        font-size: 1rem;
    }

    .profile-note {
        font-size: 1rem;
    }

    .subscribed__card-header__note {
        font-size: 1.4rem;
    }

    .subscribe {
        margin: 3rem 0 0 0;
    }

    .subscribe-note {
        text-align: center;
    }

    .subscribe__card-cont {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        max-width: 1000px;
    }

    .cont__card-header {
        font-size: 2rem;
    }

    .subscribe__card-header {
        font-size: 1.4rem;
    }

    .subscribe__header {
        font-size: 1.8rem;
        text-align: center;
    }

    .subscribe__header-note {
        font-size: 1.2rem;
        text-align: center;
    }

    .cont__card-info {
        font-size: 1.1rem;
    }

    .cont__card-form__btn {
        font-size: 1rem;
    }
}