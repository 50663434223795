.subscribe-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.subscribe-img-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.subscribe-img {
    object-fit: cover;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.authed_subscribe-header {
    text-align: left;
    width: 100%;
}

.authed_subcribe-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: auto !important;
}

.subscribe__header {
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
    .subscribe__header {
        font-size: 1.5rem;
    }
}