.home {
    margin-bottom: 4rem;
}

.home__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home__container-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home__container-item__btn-arrow-icon {
    margin-left: .4rem;
    position: relative;
    top: 1px;
}

.home__container-item__header {
    font-size: 1.9rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
    max-width: 65rem;
}

.home__container-item__p {
    font-size: 1.1rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: center;
    opacity: .8;
    max-width: 45rem;
}

.home__container-item__input-wrapper {
    display: flex;
    /* border-radius: 15px; */
    overflow: hidden;
    width: 100%;
    margin-top: .8rem;
}

.home__container-item__input {
    flex: 1;
    height: 3rem;
    border: 1px solid var(--var-color-text);
    border-radius: 0;
    padding: 0 .7rem;
    font-size: .9rem;
    font-weight: 400;
    width: 100%;
}

.home__container-item-note {
    font-size: 1rem;
    font-weight: 400;
    opacity: .9;
}

.home__container-item__input:focus {
    border-radius: 0;
    outline: none;
    border: 1px solid var(--var-color-tertiary);
}

.home__container-item__btn {
    height: 3rem;
    font-weight: 700;
    font-size: .9rem;
    background: var(--var-color-text);
    color: #fff;
    border: none !important;
    /* border-radius: 0 15px 15px 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s;
    max-width: 15rem;
}

.home__container-item__btn:hover {
    cursor: pointer;
    opacity: .8;
    color: var(--var-color-background)
}

.home__container-item__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 40rem;
}

.home__container-item__form-thanks {
    text-align: center;
    padding: 1rem;
    font-size: .8rem;
    font-weight: 300;
    width: 100%;
    background: var(--var-color-primary);
}

.home-blog__container {
    display: grid;
    grid-template-columns: 1fr;

}

.home-blog__container-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.home-blog__container-item__header {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: .5rem;
}

.small {
    margin-bottom: 1rem;
}

.dark {
    background: #585858 !important;
    color: var(--var-color-background);
}

.title-small {
    font-size: .8rem;
    font-weight: 300;
    font-size: 1.1rem !important;
    height: 4rem !important;
}

.body-small {
    font-size: .7rem;
    text-align: left;
    width: 100%;
    margin: 0;
}

.link-small {
    font-size: .8rem !important;
}

.subscribe-note {
    font-size: 1rem;
    font-weight: 300;
    opacity: .9;
    margin-top: .5rem;
}


@media screen and (min-width: 768px) {

    .subscribe-note {
        font-size: 1.1rem;
    }

    .home-blog__container-item {
        padding: 2rem;
    }

    .small-first {
        position: relative;
        left: -2rem;
    }

    .small-second {
        position: relative;
        right: -2rem;
    }

    .home-blog__container {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .home__container-item__header {
        font-size: 2.5rem;
    }

    .home__container-item__input {
        font-size: 1.1rem;
    }

    .home__container-item__btn {
        font-size: 1.1rem;
        max-width: 20rem;
    }

    .home__container-item__p {
        font-size: 1.2rem;
    }

    .home__container-item__form-thanks {
        font-size: .9rem;
    }
}

@media screen and (min-width: 1024px) {
    .body-small {
        font-size: .9rem !important;
    }

    .link-small {
        font-size: .9rem !important;
    }

    .title-small {
        font-size: 1.2rem !important;
    }
}

/* Features Section */
.home__features-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 1.2rem !important;
}

.home__features-list__item {
    align-items: center;
    background: var(--var-color-background);
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 2rem 0;
    padding: 1.5rem;
    width: 100%;
    height: 100%;
}

.home__features-list__item--highlighted {
    background: var(--var-color-text);
    color: #fff;
}

.home__features-list__item-header {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    max-width: 15rem;
    margin: 0.2rem 0 0 0;
}

.home__features-list__item-text {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    max-width: 15rem;
    opacity: .9;
    margin: .5rem 0 0 0;
}

.home__features-list__item-icon {
    font-size: 2.5rem;
    color: var(--var-color-text);
    margin-bottom: 1rem;
}

.home__features-list__item-icon--highlighted {
    color: #fff;
}

.home__features-list__item-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.features-header {
    margin-bottom: 2rem;
    font-size: 1.8rem;
}

@media screen and (min-width: 768px) {
    .home__features-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
        margin-top: 3rem;
    }

    .home__features-list__item-header {
        font-size: 1.3rem;
    }

    .home__features-list__item-text {
        font-size: 1.1rem;
    }
}

/* Testimonials */
.home__testimonials-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

.home__testimonials-list__item {
    background: var(--var-color-background);
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem;
}

.home__testimonials-list__item-name {
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: .5rem;
}

.home__testimonials-list__item-quote {
    font-size: .9rem;
    font-weight: 400;
    padding-bottom: 1.5rem;
    opacity: .9;
    font-style: italic;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.home__testimonials-list__item-quote-icon {
    font-size: 2.2rem;
    margin-right: .5rem;
    margin-bottom: 1rem;
    color: var(--var-color-text);
}

.home__testimonials-list__item-affiliation {
    font-size: 1rem;
    font-weight: 400;
    opacity: .8;
    margin-bottom: 0;
}

@media screen and (min-width: 768px) {
    .home__testimonials-list {
        grid-template-columns: 1fr 1fr;
    }

    .home__testimonials-list__item-quote {
        font-size: 1rem;
    }

    .home__testimonials-list__item-quote-icon {
        font-size: 2.5rem;
    }

    .home__testimonials-list__item-name {
        font-size: 1.2rem;
    }
}

/* How It Works */
.home__how-it-works-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    /* border-radius: 15px; */
    padding: .7rem;
}

.home__how-it-works-container__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
    height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.home__how-it-works-container__item:last-of-type {
    border-bottom: none;
}

.home__how-it-works-container__item-header {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: .5rem;
}

.home__how-it-works-container__item-text {
    font-size: 1rem;
    font-weight: 400;
    opacity: .9;
    margin-bottom: 0;
}

.home__how-it-works-container__img {
    width: 80%;
    height: 100%;
    display: none;
}

.subscribe__card-cont {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 0;
    padding: 0;
}

.subscribe__card-body p {
    margin-bottom: .5rem !important;
}

@media screen and (min-width: 768px) {
    .home__how-it-works-container {
        padding: 2rem;
    }

    .home__how-it-works-container__img {
        display: block;
        width: 20%;
    }

    .home__how-it-works-container__item {
        border-bottom: none;
    }

    .home__how-it-works-container__item-header {
        font-size: 1.4rem;
    }

    .home__how-it-works-container__item-text {
        font-size: 1.1rem;
    }

    .subscribe__card-cont {
        grid-template-columns: 1fr;
    }
}

@media screen and (min-width: 1440px) {
    #how-it-works {
        max-width: 80%;
    }
}

/* FAQ */
.faq {
    margin-top: 4rem;
    width: 100%;
    padding: 3rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100px;
}

.accordian-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.accordian-item-header:hover {
    cursor: pointer;
}

.accordian-item-p {
    font-size: 1rem;
    color: var(--var-color-text);
    opacity: .8;
}

.accordian-item__icon {
    position: relative;
    top: -4px;
}

.faq-accordian__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 100%;
    padding: 0 1rem;
}

.accordian-item {
    width: 100%;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.accordian-item__header {
    padding: 1rem 0rem;
    font-size: 1rem;
    font-weight: 600;
    display: inline;
}

.accordian-item__p {
    font-size: .9rem;
    color: var(--var-color-text);
    opacity: .8;
    width: 100%;
    text-align: left;
}


@media screen and (min-width: 1000px) {
    .faq {
        padding: 4rem 2rem;
    }

    .faq__header {
        font-size: 1.8rem;
    }

    .faq-accordian__container {
        padding: 0;
        width: 70%;
    }

    .faq__header {
        text-align: center;
        margin-bottom: 2rem;
        font-size: 1.8rem;
    }

    .accordian-item__p {
        font-size: 1rem;
    }

    .accordian-item__header {
        font-size: 1.2rem;
    }
}

/* Air Table */
.airtable-cont {
    border: 1px solid var(--var-color-text);
}

.airtable-header {
    margin: 1rem 0;
    text-align: left;
    font-size: 2.2rem;
}

.airtable-header-sm {
    margin: 1rem 0;
    text-align: left;
    font-size: 1.8rem;
}

.airtable-p {
    font-size: 1rem;
    color: var(--var-color-text);
    opacity: .8;
    width: 100%;
    text-align: left;
}

.airtable-note {
    font-style: italic;
}

@media screen and (min-width: 768px) {
    .airtable-header {
        font-size: 2.6rem;
    }

    .airtable-p {
        font-size: 1.1rem;
    }
}

/* Pricing */
.home__pricing-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.home__pricing-img {
    position: relative;
    top: -2rem;
    display: none;
    width: 100%;
    max-width: 40rem;
}

.home__pricing-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: var(--var-color-background);
    color: var(--var-color-text);
    border-radius: 3px;
    width: 100%;
    max-width: 50rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

.home-sponsors__header {
    font-size: 1.9rem;
}

.home__pricing-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.home__pricing-card__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0 .5rem 0;
}

.home__pricing-card__header-h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: .3rem;
    width: 100%;
    text-align: left;
}

.home__pricing-card__header-p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
    opacity: .8;
    text-align: left;
    text-wrap: nowrap;
    font-style: italic;
}

.home__pricing-card__header__cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.home__pricing-card__icon {
    font-size: 2rem !important;
    position: relative;
    top: -10px;
}

.home__pricing-card-section__cont {
    width: 100%;
}

.home__pricing-card__list {
    text-align: left;
    width: 100%;
    margin-bottom: 1.5rem;
    list-style-type: none;
    padding: 0;
}

.home__pricing-card__list-item {
    font-size: .9rem;
    font-weight: 400;
    margin-bottom: .5rem;
}

.home__pricing-card__price {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: .5rem;
    text-align: left;
    width: 100%;
}

.home__pricing-card__price__time {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.home__pricing-card__icon {
    font-size: 3rem;
    margin: 1rem 0;
}

.instructions {
    margin-top: 3rem;
}

.home__pricing-card__btn {
    border-radius: 3px;
    font-weight: 700;
    transition: all .3s;
    background: var(--var-color-text) !important;
    color: var(--var-color-primary);
    font-size: 1rem;
    margin: 1rem 0 0 0;
}

.home__pricing-card__btn:hover {
    color: var(--var-color-primary);
    opacity: .8;
}

.home__pricing-header {
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
    width: 100%;
}

.home__pricing-card__header-note {
    font-size: 1.1rem;
    margin-top: .5rem;
    font-weight: 400;
    font-style: italic;
}

.home__pricing-card__text {
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    width: 100%;
    margin: .3rem 0;
    color: #2b2b2b;
}

.home__pricing-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.home__pricing-card__content-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}

.home__pricing-img {
    max-width: 100%;
}

.home__pricing-card__content-left {}

.home__pricing-card__content-right {}

@media screen and (min-width: 768px) {

    .home__pricing-container {
        flex-direction: row;
        gap: 1rem;
    }

    .home__pricing-card__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .home__pricing-card__btn {
        font-size: 1.1rem;
    }

    .home__pricing-card {
        height: 100%;

    }

    .home__pricing-card__header {
        font-size: 1.6rem;
    }

    .home__pricing-img {
        display: block;
    }

    .home__pricing-header {
        font-size: 1.8rem;
    }

    .home__pricing-card__header-h3 {
        font-size: 1.7rem;
    }

    .home-sponsors__header {
        font-size: 2.2rem;
    }
}