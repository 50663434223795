.review {
    background: var(--var-color-primary);
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
}

.review-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 50rem;
    padding: .5rem;
}

.review-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.review-form-label {
    font-size: 1rem;
    font-weight: 600;
    margin: .6rem 0 .3rem 0;
    width: 100%;
    text-align: left;
    padding: 0;
}

.review-form-radio {
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0 0 .6rem 0;
}

.review-form-input {
    margin-right: .2rem;
}

.review-radio-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.review-input {
    width: 100%;
    margin-bottom: .3rem;
    padding: .5rem;
    font-size: .8rem;
}

.review-btn {
    width: 100% !important;
    margin-bottom: .3rem;
    font-weight: 600;
    font-size: .9rem;
}

.review-header {
    font-size: 1.2rem;
    font-weight: 600;
    padding-bottom: .5rem;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
}

.review-text {
    text-align: left;
    width: 100%;
    font-size: .9rem;
}

@media screen and (min-width: 768px) {
    .review-text {
        font-size: 1rem;
    }

    .review-btn {
        font-size: 1rem;
    }
}