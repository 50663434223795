.admin-header__cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.admin-header {
    width: 100%;
    padding: 1rem 0;
    border-bottom: 1px solid #e0e0e0;
    font-size: 1.4rem;
}

.admin-dash__cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-dash__btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-top: 2rem;
}

.admin-dash__btn {
    background: var(--var-color-text);
    color: var(--var-color-primary);
    width: 10rem;
    font-weight: 600;
    font-size: 1rem;
    padding: .3rem;
    margin: .3rem 0;
}

.admin-dash__text {
    font-size: .9rem;
    font-weight: 400;
    color: var(--var-color-text);
    text-align: left;
    width: 100%;
}

.admin-dash__form-input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: .3rem;
}

.admin-dash__form-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    margin: .5rem 0 0 0;
}

.admin-dash__form-input--size {
    width: 10rem !important;
    margin: .4rem 0 .1rem 0;
    padding: .3rem;
    background: var(--var-color-background);
    color: var(--var-color-text);
    font-weight: 600;
    border: 1px solid var(--var-color-text);
}

.admin-dash__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    display: none;
}

.admin-dash__form-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.admin-dash__form-input {
    width: 100%;
    margin: .4rem 0 .1rem 0;
}

.admin-dash__form__email-link {
    color: var(--var-color-text);
    font-size: 1rem;
    font-weight: 300;
    text-decoration: none;

}

.admin-dash__form__btn {
    width: 10rem;
    background: var(--var-color-text);
    color: var(--var-color-primary);
    font-size: .8rem;
}

.admin-dash__form-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 1rem;
    background: #b9b9b9;
}

.active {
    display: flex;
}

/* Change every other item to slightly different color */
.admin-dash__form-item:nth-child(odd) {
    background: #ededed
}

.admin-dash__form-input {
    margin: .4rem 0 .1rem 0;
    padding: .3rem;
    font-size: .8rem;
    width: 100%;
}

.admin-dash__sponsor-approve {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: .6rem;
    gap: .5rem;
}

.admin-dash__form-btn {
    background: var(--var-color-text);
    color: var(--var-color-primary);
    width: 10rem;
    font-weight: 700;
    font-size: .8rem;
    padding: .3rem;
    margin: .6rem 0 0 0;
}

.admin-dash__form-btn:focus {
    outline: none;
}

.admin-dash__form-btn:nth-child(1) {
    background: #196f66;
}

.admin-dash__form-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.admin-dash__form-accordian-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: var(--var-color-text);
}

.admin-dash__form-accordian {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 3px;
    background: var(--var-color-text);
    color: var(--var-color-primary);
}

.admin-dash__form-accordian-header-text {
    font-size: 1rem;
    font-weight: 600;
    color: var(--var-color-primary);
}

@media screen and (min-width: 1024px) {
    .admin-dash__form {}

    .admin-dash__form-input {
        font-size: 1rem;
    }

    .admin-dash__form-btn {
        font-size: 1rem;
    }
}