.newsletter-text-cont {
    background: var(--var-color-primary) !important;
    color: var(--var-color-text) !important;
    padding: 1rem;
}

.newsletter-form__header {
    width: 100% !important;
    border-bottom: 1px solid rgb(198, 189, 189, 0.5);
    padding-bottom: .7rem;
    font-size: 1.7rem !important;
    font-weight: 800 !important;
}

.newsletter-posts__header {
    font-size: 1.3rem;
    font-weight: 800;
    margin-bottom: .7rem;
}

.newsletter-form__p {
    font-size: 1rem;
    font-weight: 600;
    margin-top: .8rem;
}

.newsletter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
}

.newsletter-form__container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem !important;
    width: 100%;
    max-width: 1440px !important;
}

.newsletter-post {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 1rem;
    background: var(--var-color-text);
    color: #fff;
    margin: 0 0 1rem 0;
    height: 100%;

}

.newsletter-form__btn {
    padding: .5rem 2rem;
    font-size: .9rem !important;
    margin: 0 0 .4rem 0;
    font-weight: 700;
    transition: all .3s;
    border-radius: 3px;
}

.newsletter-post__link {
    font-size: .9rem;
    font-weight: 600;
    width: 100%;
    color: #fff;
    text-decoration: underline;
}

.newsletter-post__link:hover {
    color: #fff;
    opacity: 0.8;
}

.newsletter-post__header {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0rem;
    width: 100%;

}

.newsletter-post__header-cont {
    height: 100%;
}

.newsletter-post__text {
    font-size: 1rem;
    font-weight: 500;
    margin-top: .8rem;
    width: 100%;
}

.newsletter-posts-cont {
    width: 100%;
    padding: 1rem;
}

.newsletter-posts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

@media screen and (min-width: 985px) {
    .newsletter-posts {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
    }

    .newsletter-post {
        margin: 0;
    }

    .newsletter-form__btn {
        font-size: 1rem !important;
    }

    .newsletter-text-cont {
        padding: 2rem;
    }
}