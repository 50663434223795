.navbar {
    width: 100%;
    max-width: 1600px;
    flex-direction: row;
    padding-top: 1rem;
    padding-bottom: 0rem;
    background: var(--var-color-background);
    justify-content: space-between;
    margin: 0;
    margin-bottom: 1rem !important;
}

.navbar-wrap {
    width: 100%;
    background: var(--var-color-background);
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: 0 0 0rem 0;
}

#navbarNav {
    justify-content: flex-end;
}

.navbar-toggler,
.navbar-toggler:focus {
    color: var(--var-color-text) !important;
    border: none;
    outline: none;
}

.nav-icon {
    font-size: 1.1rem;
}

.nav-icon__dark {
    color: var(--var-color-background);
}

.nav-item {
    margin: 0rem;
    padding: .3rem;
}

.nav-link {
    color: var(--var-color-text) !important;
    font-weight: 600;
    padding: .5rem 1rem;
    font-size: .9rem;
}

.nav-link__dark {
    color: var(--var-color-background) !important;
}

.nav-link:hover {
    opacity: 0.6;
}

.navbar-brand {
    font-size: 1.4rem;
    font-weight: 700 !important;
    color: var(--var-color-text) !important;
}

.nav-item__highlight {
    background: var(--var-color-primary);
    width: auto;
    padding: .3rem .6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 .8rem 0;
}

.nav-link__highlight {
    font-weight: 600;
    font-size: .9rem;
}

.nav-link__highlight-dark {
    color: #fff !important;
}

.nav-item__highlight-dark {
    background: var(--var-color-text) !important;
    color: var(--var-color-text) !important;
}

.navbar__dark {
    background: var(--var-color-text);
}

.navbar-brand__dark {
    color: var(--var-color-background) !important;
}


@media screen and (min-width: 985px) {
    .navbar {
        margin-bottom: 9rem;
    }

    .nav-item__highlight {
        width: auto;
        padding: 0 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 0 1rem;
    }
}