.login-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
}

.login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    max-width: 440px;
    font-size: .8rem;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.login-form__header {
    text-align: left;
    font-weight: 700;
    font-size: 1.2rem;
    width: 70%;
}

.login-form__header-cont {
    text-align: left;
    font-weight: 700;
    font-size: 1.2rem;
    width: 100%;
    margin-bottom: .5rem;
}

.login-form__header-note {
    font-size: .9rem;
    width: 100%;
    margin-bottom: 0rem;
    font-weight: 400;
    font-style: italic;
}

.login-form__input {
    padding: .5rem;
    font-size: .8rem;
}

.login-form__btn {
    width: 15rem;
    background: var(--var-color-text);
    color: var(--var-color-primary);
    transition: all .3s;
    font-size: 1rem;
    font-weight: 700;
    margin: .2rem 0 0 0;
}

.login-form__btn-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}



.loign-form__btn:focus {
    outline: none !important;
}

.login-form__btn:hover {
    color: var(--var-color-primary);
}

.login-form__btn:disabled {
    opacity: 0.5;
    cursor: default;
}

.login-form__link {
    width: 100%;
    text-align: left;
    margin: .7rem 0 0 0;
    font-size: .9rem;
}

@media screen and (min-width: 768px) {
    .login-form {
        padding: 2rem;
    }

    .login-form__header {
        font-size: 1.4rem;
    }

    .login-form__input {
        font-size: .9rem;
    }

    .login-form__header-note {
        font-size: 1rem;
    }
}