:root {
  --var-color-primary: #f1f1f1;
  --var-color-secondary: #343a40;
  --var-color-tertiary: #6677CC;
  --var-color-background: #fff;
  --var-color-text: #090913;
  --var-color-text-light: rgba(2, 2, 13, .8);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
  background: var(--var-color-background);
  scroll-behavior: smooth;
  height: 100%;
}

body,
html {
  scroll-behavior: smooth !important;
  height: 100%;
}

/* Link Spacing */
#hero,
#testimonials,
#FAQ,
#sample-data,
#pricing,
#features,
#how-it-works,
#subscribe,
#blog {
  scroll-margin-top: 15rem;
}

#FAQ {
  max-width: 1200px;
}

/* Web-Container & Web-Section */
.web-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--var-color-text);
  background: var(--var-color-background);
  min-height: 100vh;
  width: 100%;
}

.web-page {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.web-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 1rem;
  margin-top: 3rem;
}

.web-section-dark {
  background: var(--var-color-primary);
}

.web-section-content {
  max-width: 1440px;
}

.web-section__container {
  width: 100%;
}

.web-section__container-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.web-section__container-header {
  text-align: center;
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}

.web-section__container-header-sm {
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 768px) {
  .web-section {
    padding: 2rem;
  }
}

@media screen and (min-width: 1024px) {

  .web-section__container-header {
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  .web-section__container-header-sm {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
}

/* Typography */
.text {
  color: var(--var-color-text-light);
}

/* Buttons */
.btn {
  color: var(--var-color-secondary);
  background: var(--var-color-primary);
  padding: .5rem 2rem;
  width: 100%;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 0rem;
}

.btn:disabled {
  opacity: .5;
  cursor: default !important;
}

.btn:disabled:hover {
  opacity: .5;
}

.text-highlight {
  color: var(--var-color-tertiary);
}

.text-dark {
  color: var(--var-color-text);
}

.bg-dark {
  background: var(--var-color-text);
}

#loading-btn {
  max-width: none !important;
}

#root {
  height: 100%;
}

/* Forms */
.disabled {
  opacity: .5;
}

.disabled:hover {
  opacity: .5 !important;
  cursor: default !important;
}

/* Forms */
.input {
  width: 100%;
  margin: 0 0 .4rem 0;
  border-radius: 0;
  border: 1px solid var(--var-color-text);
  box-shadow: none !important;
  outline: none !important;
}

.form__header {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 40rem;
}

.form-info {
  text-align: center;
  padding: 1rem;
  font-size: .8rem;
  font-weight: 300;
  width: 100%;
  background: var(--var-color-primary);
}

.form-error {
  color: red;
  font-size: .8rem;
  font-weight: 300;
  text-align: left;
  width: 100%;
  margin: 0 0 .5rem 0rem;
}

@media screen and (min-width: 768px) {
  .form-info {
    font-size: .9rem;
  }
}

/* Airtable */
.airtable-cont {
  width: 100%;
}

/* Other */
.privacy-policy {
  font-size: 1rem;
}

.policy-text h3 {
  font-size: 1.2rem;
  font-weight: 700;
}

.text-italic {
  font-style: italic;
}

.text-bold {
  font-weight: 700;
}

.align-left {
  text-align: left !important;
}

/* NavMenu */
.authed-app {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh !important;
  padding-left: .8rem;
}

.authed-app__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding-left: 2.5rem;
}