.sponsors {
    background: var(--var-color-primary);
}

.sponsors-header__cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
}

.sponsors-header {
    font-size: 1.8rem;
    font-weight: 700;

}

.sponsors-subheader {
    font-size: 1rem;
}

.sponsor-table {
    background: var(--var-color-primary);
    width: 100%;
    display: none;
    /* On mobile devices use card layout */
}

.sponsor-table__cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
}

.sponsor-table__cont-header {
    width: 100%;
    font-weight: 700;
    margin: 0;
    font-size: 1.4rem;
}

.sponsor-table__cont-header-p {
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
    margin: .5rem 0;
}

.sponsor-table__cont-header-h3 {
    font-size: 1.2rem;
    font-weight: 700;
    width: 100%;
    margin: 1rem 0 .3rem 0;
}

.sponsor-table__header {
    padding: .5rem 1rem;
    border: 1px solid #7d757591;
    font-size: .9rem;
    margin: .2rem 1rem 0 0;
    text-align: left;
}

.sponsor-tables__cont {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
    flex-wrap: wrap;
}

.sponsor-tables__cont-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #7d757591;
    border-radius: 3px;
    padding: 1rem;
    font-weight: 600;
    font-size: .8rem;
    cursor: pointer;
    transition: all .3s;
    color: var(--var-color-text);
}

.sponsor-tables__cont-link:hover {
    opacity: 0.7;
    border: 1px solid #7d7575;
    color: var(--var-color-text);
    text-decoration: none;
}

.sponsor-tables__cont-link__icon {
    font-size: 1.5rem;
    margin: 0 0 .5rem 0;
}

.link-technology {
    background: #d1e2ff;
}

.link-health {
    background: #fad2fc;
}

.link-finance {
    background: #cff5d1;
}

.link-lifestyle {
    background: #fce0cc;
}

.link-marketing {
    background: #c1f5f0;
}

.link-education {
    background: #f2dcf0;
}

.link-ecommerce {
    background: #f5f0c1;
}

.sponsor-table__cont-p {
    font-size: .8rem;
    font-weight: 500;
    margin: 0 0 .5rem 0;
    text-align: left;
    width: 100%;
}


@media screen and (min-width: 768px) {
    .sponsor-tables__cont-link {}

    .sponsor-table__cont-p {
        font-size: .9rem;
    }

    .sponsor-table__cont-header {
        font-size: 1.8rem;
    }
}