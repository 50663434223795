.change-password-form__header {
    font-size: 1.2rem;
    font-weight: 700;
}

.change-password-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.change-password-form {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.password-form-error {
    color: red;
    font-size: .9rem;
    margin: 0 0 .4rem 0;
}

.change-password-form__input {
    width: 100%;
    border-radius: 0;
    border: 1px solid var(--var-color-text);
    box-shadow: none !important;
    outline: none !important;
    padding: .5rem;
    font-size: .9rem;
}

.change-password-form__btn {
    color: var(--var-color-primary);
    background: var(--var-color-text);
    padding: .5rem 2rem;
    font-size: 1rem;
    margin: 0 0 .4rem 0;
    font-weight: 700;
    transition: all .3s;
}

.change-password-form__btn:hover {
    color: var(--var-color-primary);
    opacity: .8;
}

.change-password-form__link {
    font-size: 1rem;
}

.change-password-form__p {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 .4rem 0;
}

@media screen and (min-width: 768px) {}