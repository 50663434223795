.footer {
    width: 100%;
    min-height: none;
    margin: 0;
    background: var(--var-color-text);
    color: var(--var-color-primary);
    position: static;
    padding: 1rem;
}

.footer-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.footer-copy {
    font-size: .9rem;
}

.footer-item {
    color: #fff;
    font-size: .85rem;
    margin: 0 1rem .5rem 0;
}

.footer-item__cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.footer-item:hover {
    color: #ffffffdb;
    text-decoration: none;
}

.footer-item__highlight {
    background: var(--var-color-primary);
    width: 100%;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.footer-item__highlight-arrow-icon {
    position: relative;
    left: -2px;
}

@media screen and (min-width: 1024px) {


    .footer-item__highlight {
        width: auto;
    }
}